@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .home-icon {
        @apply relative flex items-center justify-start content-start
        h-auto w-auto mt-2 mb-2 mx-auto
        text-4xl text-orange-500 antialiased
        hover:text-orange-600;
    }

    .navbar-icon {
        @apply relative flex items-center justify-center
        h-auto w-auto mt-2 mb-2 mx-auto
        font-semibold text-3xl text-blue-500 antialiased
        hover:underline;
    }

    /*
        sticky with top-0 so that the navbar's position is stickied onto the top of the page.
        z-50 to force it to appear on top of everything else.
        bg-white so that it will completely hide elements going under it.
        w-full instead of w-screen because it was causing some horizontal overflow issues
    */
    .navbar-wrapper {
        @apply sticky top-0 z-50 bg-white w-full h-20 m-0 flex flex-row bg-blend-color text-white shadow-lg;
    }

    .errmsg {
        @apply text-xs rounded-lg text-black p-1 relative border-2 border-red-300;
    }
}
